import { useSortingStore } from '@/stores/sort';
import { deepCloneReactive } from '@/shared/utils';
import type { FiltersState } from '@/stores/filters/types';
import type { BaseFilters } from '~/shared/types';

export function useFilterSort() {
  const filtersStore = useFiltersStore();
  const { resetFilters, applyFiltersToState } = filtersStore;
  const {
    filters,
    initialGlobalFilters,
    filtersParams,
    hasAcceptedFilters,
  } = storeToRefs(filtersStore);
  const filterSortStore = useFilterSortStore();
  const { filtersQueryParams } = storeToRefs(filterSortStore);

  const localFilters = deepCloneReactive<FiltersState>(filters.value);

  function assignLocalFiltersToFilters(params?: BaseFilters, oldParams?: BaseFilters): void {
    if (params?.price_range.currency_code !== oldParams?.price_range.currency_code) {
      localFilters.priceFrom = filters.value.priceFrom;
      localFilters.priceTo = filters.value.priceTo;
    } else {
      localFilters.squareFrom = filters.value.squareFrom;
      localFilters.squareTo = filters.value.squareTo;
    }
  }

  function resetLocalFilters(): void {
    Object.assign(localFilters, deepCloneReactive(initialGlobalFilters.value));
  }

  const sortingStore = useSortingStore();
  const {
    availabilitySortingOptions,
    productSortingOptions,
    updateSortingByName,
    applySortToState,
  } = sortingStore;
  const {
    sorting,
    currentSortingOption,
  } = storeToRefs(sortingStore);

  return {
    filters,
    localFilters,
    filtersParams,
    filtersQueryParams,
    hasAcceptedFilters,
    resetFilters,
    applyFiltersToState,
    resetLocalFilters,
    assignLocalFiltersToFilters,

    sorting,
    currentSortingOption,
    productSortingOptions,
    availabilitySortingOptions,
    updateSortingByName,
    applySortToState,
  };
}
